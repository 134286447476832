/*
 * IMPORTS
 */
import common from './common'


/*
 * GLOBALS
 */
const Index = {
  'modes': {
    'light': {
      ...common,
      'black': '#ffffff',
      'blackShadow': 'rgba(242, 242, 242, 0.06)',
      'clientsFadeGradient1': 'rgba(255, 255, 255, 0.76)',
      'clientsFadeGradient2': 'rgba(255, 255, 255, 0.00)',
      'semiLightBlack': '#f4f4f4',
      'lightestBlack': '#f8f8f8',
      'lightBlack': '#dbdbdb',
      'darkBlack': '#acacac',
      'darkestWhite': '#101010',
      'darkerWhite': '#202020',
      'lightDarkWhite': '#323232',
      'darkWhite': '#292929',
      'white': '#000'
    },
    'dark': {
      ...common,
      'blackShadow': 'rgba(0, 0, 0, 0.06)',
      'clientsFadeGradient1': 'rgba(0, 0, 0, 0.76)',
      'clientsFadeGradient2': 'rgba(0, 0, 0, 0.00)',
      'black': '#000',
      'semiLightBlack': '#292929',
      'lightestBlack': '#323232',
      'lightBlack': '#202020',
      'darkBlack': '#101010',
      'darkestWhite': '#dbdbdb',
      'darkerWhite': '#acacac',
      'darkWhite': '#e6e6e6',
      'lightDarkWhite': '#f8f8f8',
      'white': '#ffffff'
    }
  }
}


/*
 * EXPORTS
 */
export default Index
