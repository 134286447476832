/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import Head from 'next/head' // NPM: babel polyfill library.
import Script from 'next/script' // NPM: Next.js script library.
import PageLoader from 'nextjs-progressbar' // NPM: Next.js progress bar.
import Link from 'next/link' // NPM: Next.js link library.
import { Provider } from 'react-redux' // NPM: react redux library.
import { PersistGate } from 'redux-persist/integration/react' // NPM: react persist library.
import { ThemeProvider } from 'styled-components' // NPM: styled-components for react.js.
import { SiWorkplace } from 'react-icons/si' // NPM: react icons library.
import { RiCustomerService2Line, RiInstagramFill, RiUserStarLine, RiArticleFill } from 'react-icons/ri' // NPM: react icons library.
import { RxLinkedinLogo } from 'react-icons/rx' // NPM: react icons library.
import { BsFacebook } from 'react-icons/bs' // NPM: react icons library.
import { MdOutlineCoffee, MdOutlinePrivacyTip } from 'react-icons/md' // NPM: react icons library.
import { Analytics } from '@vercel/analytics/react' // NPM: Next.js analytics library.
import { Poppins, Source_Code_Pro } from '@next/font/google' // NPM: Google font library.


/*
 * PACKAGES
 */
import '~packages/iife'
import Redux, { Persist } from '~packages/redux'


// vjhggz
/*
 * STYLES
 */
import './../index.css'
import '@glidejs/glide/dist/css/glide.core.min.css'
import 'rc-drawer/assets/index.css'
import GlobalStyleSheet from '~packages/common/style'
import DualToneGlobalStyleSheet from '~packages/common/style/index.dualtone'
import GradientStripe from '~packages/lib/Gradient'


/*
 * GLOBALS
 */
const _Poppins = Poppins({
  display: 'swap',
  weight: [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900'
  ],
  preload: true
})
const _SourceCodePro = Source_Code_Pro({ display: 'swap', weight: '500', preload: true })
const _showDualTone = false


/*
 * OBJECTS
 */
const Index = ({ Component, fakePage, pageProps }) => {
  // Hook assignment.
  const [theme, setTheme] = React.useState(Redux.getState().Theme)
  const [forceReRender, setForceReRender] = React.useState(false)

  // Event handler.
  Redux.subscribe(() => {
    // Update state of theme.
    setTheme(Redux.getState().Theme)
  })
  React.useEffect(() => {
    // Initialize gradient.
    new GradientStripe({ 'seed': 786 }).initGradient('#AppGradient')
  }, [forceReRender])
  React.useEffect(() => {
    // Watch for event when user system theme changes.
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      // Const assignment.
      const _scheme = event.matches ? 'dark' : 'light'

      /*
       * Only update theme if it doesn't matches
       * with a theme available.
       */
      if (_scheme !== theme.mode) {
        /*
         * Update website color theme based on user
         * preferences.
         */
        Redux.dispatch({ 'type': 'THEME_UPDATE', 'Theme': { 'mode': _scheme } })
      }
    })
  })

  // Return component.
  return (
    <Provider store={Redux}>
      <PersistGate persistor={Persist}>
        <ThemeProvider theme={theme}>
          <Head>
            <title>Rootandleaves | A Leader in Software Development</title>
            <meta name='application-name' content='rootandleaves.com' />
            <meta name='theme-color' content={theme.colors.black} />
            <meta name='apple-mobile-web-app-capable' content='yes' />
            <meta name='apple-mobile-web-app-status-bar-style' content='default' />
            <meta name='apple-mobile-web-app-title' content='rootandleaves.com' />
            <meta name='description' content='webservices platform' />
            <meta name='format-detection' content='telephone=no' />
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='msapplication-config' content='/static/browserconfig.xml' />
            <meta name='msapplication-TileColor' content={theme.colors.black} />
            <meta name='msapplication-tap-highlight' content='no' />
            <meta name='theme-color' content={theme.colors.black} />
            <meta name='description' content='Rootandleaves is a leader in software development, with a focus on innovative and beautiful solutions in JavaScript, Python and Rust languages. We provide IT support and digital marketing services to businesses in Bangalore and Gurugram.' />
            <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=5, viewport-fit=cover' />
            <meta property='og:type' content='website' />
            <meta property='og:title' content='rootandleaves.com' />
            <meta property='og:description' content='Rootandleaves is a leader in software development, with a focus on innovative and beautiful solutions in JavaScript, Python and Rust languages. We provide IT support and digital marketing services to businesses in Bangalore and Gurugram.' />
            <meta property='og:site_name' content='rootandleaves.com' />
            <meta property='og:url' content='https://rootandleaves.com' />
            <meta property='og:image' content='/static/image/apple-touch-icon.png' />
            <link rel='icon' type='image/png' href='/static/image/favicon.png' />
            <link rel='canonical' href='https://rootandleaves.com/' />
            <link rel='apple-touch-icon' sizes='180x180' href='/static/image/apple-touch-icon.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/static/image/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/static/image/favicon-16x16.png' />
            <link rel='manifest' href='/site.webmanifest' />
            <link rel='mask-icon' href='/static/image/safari-pinned-tab.svg' color={theme.colors.primary} />
            <meta name='msapplication-TileColor' content={theme.colors.black} />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
          </Head>
          <Script strategy='beforeInteractive' href='https://cdn.jsdelivr.net/npm/regl@1.3.1/dist/regl.min.js' />
          <Script strategy='beforeInteractive' src='https://www.googletagmanager.com/gtag/js?id=G-RKP3VW1SPV' />
          <Script strategy='lazyOnload' src='/static/script/Drawer/index.js' />
          <Script id='google-analytics' strategy='afterInteractive'>
            {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-RKP3VW1SPV', { page_path: window.location.pathname });
            `}
          </Script>
          <Script id='google-tag-manager' strategy='afterInteractive'>
            {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-TD3WTN5');
            `}
          </Script>
          {_showDualTone ? <DualToneGlobalStyleSheet /> : <GlobalStyleSheet />}
          <PageLoader
            color={theme.colors.primary}
            startPosition={0.3}
            stopDelayMs={200}
            height={3}
            showOnShallow={true}
            options={{ 'showSpinner': false }}
          />
          <section className={['gradientWrapper', _Poppins.className].join(' ')}>
            <canvas id='AppGradient' className='gradient color theme' />
            <div className='overlay occlusion' />
            <section className='stackWrapper'>
              <section className='stackContainer'>
                <section className='stackNavigationWrapper'>
                  <p className='subHeading transparent font weight900 width maxContent'>References & Links</p>
                  <nav className='stackNavigationContainer'>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' href='/work'><SiWorkplace size={17} />Work</Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' href='/service'><MdOutlineCoffee size={17} />Services</Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' href='/radices'><RiUserStarLine size={17} />Radices</Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' href='/policies'><MdOutlinePrivacyTip size={17} />Policies</Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' href='/contact'><RiCustomerService2Line size={17} />Contact</Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' href='/blog'><RiArticleFill size={17} />Blogs</Link>
                    </div>
                  </nav>
                </section>
                <section className='socialMediaWrapper'>
                  <p className='subHeading transparent width maxContent font weight900'>News & Media</p>
                  <div className='socialMediaContainer'>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' rel='noreferrer' target='_blank' href='https://www.youtube.com/@rootandleaves3518' passHref><BsFacebook size={18} /></Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' rel='noreferrer' target='_blank' href='https://www.linkedin.com/company/root-and-leaves' passHref><RxLinkedinLogo size={19} /></Link>
                    </div>
                    <div className='linkWrapper'>
                      <Link className='link linkComponent' rel='noreferrer' target='_blank' href='https://www.instagram.com/root_and_leaves/?hl=en' passHref><RiInstagramFill size={20} /></Link>
                    </div>
                  </div>
                </section>
              </section>
              <section className='stackComponents'>
                <section className='component'>
                  <Component {...pageProps} />
                </section>
                <section className={['component', _SourceCodePro.className].join(' ')}><div style={fakePage} /></section>
                <section className='component'><div style={fakePage} /></section>
              </section>
              <button className='humBurgerActionButton' onClickCapture={() => setForceReRender(!forceReRender)}>
                <span>Menu</span>
              </button>
            </section>
          </section>
          <Analytics />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}


/*
 * PROPTYPES
 */
Index.defaultProps = {
  'fakePage': {
    'height': '100vh',
    'width': '100%'
  }
}


/*
 * EXPORTS
 */
export default Index
