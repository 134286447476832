/*
 * ESLINT
 */
/* eslint-disable max-lines */


/*
 * IMPORTS
 */
import ThemeGet from '@styled-system/theme-get' // NPM: styled-system themeGet library.
import { createGlobalStyle } from 'styled-components' // NPM: styled-components library.


/*
 * OBJECTS
 */
const Index = createGlobalStyle`

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  ::selection {
    background: ${ThemeGet('colors.lightestBlack')};
    color: ${ThemeGet('colors.white')};
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  html {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    tap-highlight-color: transparent;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    background-color: ${ThemeGet('colors.black')};
  }

  #__next {
    position: relative;
    overflow-x: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  @keyframes blinkHide {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }

  @keyframes blinkShow {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
  }

  @keyframes rotateIt {
    to {
      transform: rotate(-360deg);
    }
  }

  @keyframes moveUpAndDownArrow {
    0%,
    100% {
      transform: translateY(-2.5px);
    }
    50% {
      transform: translateY(2.5px);
    }
  }

  @keyframes fadeIn {
    100% {
      transform: scale(1.03);
      opacity: 0;
    }
  }

  @keyframes scaleAndRotateArrow {
    from {
      opacity: 0;
      transform: rotate(0deg);
    }
    to {
      opacity: 1;
      transform: rotate(-120deg);
    }
  }

  @keyframes moveArrowForwardAndBackward {
    from {
      right: -5px;
    }
    to {
      right: 5px;
    }
  }

  @keyframes animateInTopLeft {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(5px, -5px, -5px);
    }
  }

  @keyframes animeOutTopLeft {
    from {
      transform: translate3d(5px, -5px, -5px);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .reusecore__button {
    width: max-content;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 0;
    margin-right: 0;
    font-size: 15px;
    display: flex;
    border-radius: 9px;
    border-color: transparent;
    background: ${ThemeGet('colors.primary')};
    padding: 15px 20px;
    text-transform: capitalize;
    transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;
    box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};
    pointer-events: auto;
    cursor: pointer;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & * {
      font-weight: 600;
      color: ${ThemeGet('colors.white')};
    }

    &:hover {
      background: transparent !important;
      box-shadow: none;
    }

    &.white {
      background: ${ThemeGet('colors.white')};
      box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

      & * {
        font-weight: 600;
        color: ${ThemeGet('colors.black')};
      }

      &:hover {
        background: ${ThemeGet('colors.white')};
      }
    }

    &.black {
      background: ${ThemeGet('colors.black')};
      box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

      & * {
        font-weight: 600;
        color: ${ThemeGet('colors.white')};
      }
    }

    &.theme {
      background: ${ThemeGet('colors.primary')};
      box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

      & * {
        font-weight: 600;
        color: ${ThemeGet('colors.white')};
      }

      &.secondary {
        background: ${ThemeGet('colors.secondary')};
        box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

        & * {
          font-weight: 600;
          color: ${ThemeGet('colors.white')};
        }
      }
    }

    &.success {
      background: ${ThemeGet('colors.success')};

      & * {
        font-weight: 600;
        color: ${ThemeGet('colors.success')};
      }
    }

    &.danger {
      background: ${ThemeGet('colors.danger')};

      & * {
        font-weight: 600;
        color: ${ThemeGet('colors.white')};
      }
    }

    &.gradientRound {
      font-size: 15px;
      border: none;
      display: flex;
      font-weight: 600;
      margin: auto;
      gap: 5px;
      background: transparent;
      align-items: center;
      border-radius: 25px !important;
      & span {
        color: ${ThemeGet('colors.pureWhite')};

        & svg {
          fill: ${ThemeGet('colors.pureWhite')};
        }
      }

      &:hover {
        &:after {
          filter: opacity(1.0);
          transform: scale(1.1);
        }

        & svg {
          position: relative;
          animation: moveArrowForwardAndBackward 1s cubic-bezier(.19, 1, .22, 1) infinite alternate;
        }
      }

      &:after {
        content: ' ';
        background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        border-radius: 25px !important;
        filter: opacity(0.9);
        transition: transform .5s cubic-bezier(.19, 1, .22, 1), opacity .5s cubic-bezier(.19, 1, .22, 1);
      }
    }

    &.surtur {
      padding: 0;
      position: relative;
      background: none;
      clip-path: circle(40% at 50% 50%);
      & .circle {
        position: relative;
        display: block;
        width: 150px;

        & text {
          font-size: 41px;
          text-transform: uppercase;
          fill: ${ThemeGet('colors.white')};
        }
      }

      & .center {
        position: absolute;
        z-index: 10;
        width: 60px;
        height: 60px;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
      }

      & .eye {
        position: absolute;
        z-index: 10;
        width: 60px;
        height: 60px;
        top: calc(50% - 30px);
        left: calc(50% - 30px);

        & .outer,
        & .inner,
        & .lashesUp,
        & .lashesDown {
          stroke: ${ThemeGet('colors.white')};
          fill: none;
          stroke-width: 1.5px;
        }

        & .lashesDown {
          opacity: 0;
        }
      }

      &:focus-visible {
        background: ${ThemeGet('colors.primary')};
      }

      &:hover,
      &.preload {
        & .circle {
          animation: rotateIt 7s linear infinite;
        }

        & .eye .lashesUp,
        & .eye .inner,
        & .eye .iris {
          animation: blinkHide 2s step-end infinite;
        }

        & .eye .lashesDown {
          animation: blinkShow 2s step-end infinite;
        }

        & .arrow {
          animation: moveUpAndDownArrow 1.5s infinite;
        }
      }
    }
  }

  .subHeading {
    text-transform: uppercase;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    text-align: left;
    font-size: 13px;
    width: min-content;
    font-weight: 600;
    box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};
    background-color: ${ThemeGet('colors.primary')};
    border-radius: 25px;
    padding: 2.5px 10px !important;
    color: ${ThemeGet('colors.white')};

    &.revert {
      &::before {
        left: unset;
        right: -30px;
      }
    }

    &.transparent {
      padding: 0 !important;
      background-color: transparent;

      &::before {
        background-color: transparent;
      }

      &.pureWhite {
        color: ${ThemeGet('colors.pureWhite')} !important;
      }

      &.followedLine {
        &::after {
          content: ' ';
          width: 135px;
          height: 0;
          margin-left: 10px;
          background-clip: text;
          text-fill-color: transparent;
          border-bottom: 2px solid transparent;
          border-image: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
          border-image-slice: 1;
        }
      }
    }

    &.white {
      background-color: ${ThemeGet('colors.white')};
      color: ${ThemeGet('colors.black')} !important;

      &::before {
        background-color: ${ThemeGet('colors.white')};
      }
    }

    &.theme {
      background-color: ${ThemeGet('colors.primary')};
      color: ${ThemeGet('colors.white')} !important;
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.primary')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }

      &.secondary {
        background-color: ${ThemeGet('colors.secondary')};
        color: ${ThemeGet('colors.white')} !important;
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

        &::before {
          background-color: ${ThemeGet('colors.secondary')};
          box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
        }
      }
    }

    &.black {
      background-color: ${ThemeGet('colors.black')};
      color: ${ThemeGet('colors.white')};
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.black')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    &.pureBlack {
      background-color: ${ThemeGet('colors.pureBlack')};
      color: ${ThemeGet('colors.pureWhite')} !important;
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.pureBlack')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    &.danger {
      background-color: ${ThemeGet('colors.danger')};
      color: ${ThemeGet('colors.white')} !important;
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.danger')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    @media (max-width: 766px) {
      font-size: 14px;
    }
    @media (max-width: 556px) {
      font-size: 13px;
    }
  }

  .heading,
  .selfWritingHeading {
    font-size: 70px;
    font-weight: 500 !important;
    letter-spacing: 0.015em;
    line-height: 1.2;
    text-align: left;
    margin-top: 30px !important;
    margin-right: 0;
    width: auto;
    margin-bottom: 0;
    position: relative;
    margin-left: 0;
    color: ${ThemeGet('colors.primary')};

    &.danger {
      color: ${ThemeGet('colors.danger')};
    }

    @media (max-width: 1300px) and (min-width: 766px) {
      font-size: 81px;
    }
    @media (max-width: 771px) {
      font-size: 71px;
    }
    @media (max-width: 661px) {
      font-size: 31px;
    }
  }

  .selfWritingHeading {
    font-weight: 200;
  }

  * ~ .subHeading,
  .subHeading ~ * {
    margin-top: 10px !important;
  }

  .subHeading ~ .heading {
    margin-top: 10px !important;
  }

  .description {
    font-size: 18px;
    line-height: 1.75;
    font-weight: 400;
    text-align: left;
    width: 60%;
    margin-top: 40px !important;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: 0;
    color: ${ThemeGet('colors.black')};
    @media (max-width: 1220px) {
      width: 70%;
    }
    @media (max-width: 661px) {
      width: 100%;
    }
  }

  .description ~ .reusecore__button {
    margin-top: 60px !important;
  }

  .dIcon {
    position: relative;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
  }

  .dBanner {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    width: calc(100vw / 1.5);
    height: calc(100vw / 1.5);
    position: absolute;
    margin: auto;
    will-change: border-radius;
    z-index: 10000;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:after {
      line-height: 120px;
      text-indent: -21px;
    }

    &.positionCenter {
      top: calc(25% - 100px);
    }

    &.positionRight {
      right: 0;
      left: unset;
    }

    &.positionBottom {
      bottom: 0;
    }

    &.positionTop {
      top: 0;
    }

    &.positionLeft {
      left: 0;
      right: unset;
    }

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  .svg {
    &.stroke {
      &.theme {
        stroke: ${ThemeGet('colors.primary')};

        &.secondary {
          stroke: ${ThemeGet('colors.secondary')};
        }
      }

      &.white {
        stroke: ${ThemeGet('colors.white')};
      }

      &.blackShadow {
        stroke: ${ThemeGet('colors.blackShadow')};
      }

      &.darkWhite {
        stroke: ${ThemeGet('colors.darkWhite')};
      }

      &.lightDarkWhite {
        stroke: ${ThemeGet('colors.lightDarkWhite')};
      }
    }

    &.fill {
      &.theme {
        fill: ${ThemeGet('colors.primary')};

        &.secondary {
          fill: ${ThemeGet('colors.secondary')};
        }
      }

      &.white {
        fill: ${ThemeGet('colors.white')};
      }

      &.blackShadow {
        fill: ${ThemeGet('colors.blackShadow')};
      }

      &.darkWhite {
        fill: ${ThemeGet('colors.darkWhite')};
      }

      &.lightDarkWhite {
        fill: ${ThemeGet('colors.lightDarkWhite')};
      }
    }
  }

  .font.whiteSpacePre {
    white-space: pre !important;
  }

  .font.weight200 {
    font-weight: 200 !important;
  }

  .font.weight300 {
    font-weight: 300 !important;
  }

  .font.weight400 {
    font-weight: 400 !important;
  }

  .font.weight500 {
    font-weight: 500 !important;
  }

  .font.weight900 {
    font-weight: 900 !important;
  }

  .font.strikeThrough {
    text-decoration: line-through;
  }

  .font.black {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.black')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.black')};
      }
    }
  }

  .font.white {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.white')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.white')};
      }
    }
  }

  .font {
    &.onHoverGradient {
      &:hover {
        background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .font.white {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.white')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.white')};
      }
    }
  }

  .font.darkerWhite {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.darkerWhite')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.darkerWhite')};
      }
    }
  }

  .font.danger {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.danger')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.danger')};
      }
    }
  }

  .font.pureWhite {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.pureWhite')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.pureWhite')};
      }
    }
  }

  .font.theme {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.primary')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.primary')};
      }
    }
  }

  .font.theme.secondary {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.secondary')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.secondary')};
      }
    }
  }

  .font.gradient {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &.topToBottom {
        background: linear-gradient(180deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &.subHeading {
      &::before {
        background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  svg.hideSVG {
    display: none !important;
  }

  .width.maxContent {
    width: max-content;
  }

  .width.minContent {
    width: min-content;
  }

  .font.capitalize {
    text-transform: capitalize;
  }

  .position.unset {
    position: unset !important;
  }

  .strike {
    text-decoration: line-through;
  }

  div.scrollbar-track.scrollbar-track-x.show,
  div.scrollbar-track.scrollbar-track-y.show {
    display: none !important;
  }

  .gradientWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    & .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -10;
      &.color.theme {
        --gradient-color-1: ${ThemeGet('colors.primary')};
        --gradient-color-2: ${ThemeGet('colors.black')};
        --gradient-color-3: ${ThemeGet('colors.secondary')};
        --gradient-color-4: ${ThemeGet('colors.black')};
        --gradient-color-5: ${ThemeGet('colors.black')};
      }
      &.color.blackAndPrimary {
        --gradient-color-1: ${ThemeGet('colors.primary')};
        --gradient-color-2: ${ThemeGet('colors.black')};
      }
      &.color.blackAndSecondary {
        --gradient-color-1: ${ThemeGet('colors.secondary')};
        --gradient-color-2: ${ThemeGet('colors.black')};
      }
    }

    & .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      &:after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;                                                                                             
        box-shadow: inset 0 0 100px 200px ${ThemeGet('colors.black')}, 0 0 100px 200px ${ThemeGet('colors.blackShadow')};
      }
    }

    &.topLeft,
    &.topRight,
    &.bottomLeft,
    &.bottomRight {
      & .gradient {
        left: -20vh;
        top: -20vh;
        z-index: -10;
      }

      & .overlay {
        left: -20vh;
        top: -20vh;
        z-index: -1;
        width: 100vh;
        height: 100vh;
      }

      &.round {
        & canvas {
          width: 100vh;
          height: 100vh;
          border-radius: 100%;
          overflow: hidden;
          border: 2px solid ${ThemeGet('colors.black')};
        }

        & .overlay {
          width: 100vh;
          height: 100vh;
          border-radius: 100%;
          overflow: hidden;
          overflow: hidden;
        }
      }
    }

    &.topRight,
    &.bottomRight {
      & .gradient {
        right: -20vh;
        left: unset;
      }

      & .overlay {
        right: -20vh;
        left: unset;
      }
    }

    &.bottomLeft,
    &.bottomRight {
      & .gradient {
        top: unset;
        bottom: -20vh;
      }

      & .overlay {
        top: unset;
        bottom: -20vh;
      }
    }
  }

  .stackWrapper .stackNavigationWrapper,
  .stackWrapper .socialMediaWrapper {
    position: relative;
    flex: 1;
    & .stackNavigationContainer,
    & .socialMediaContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      pointer-events: none;
      opacity: 0;
      gap: 30px;
      background: transparent;
      transition: transform 1.2s, opacity 1.2s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      transform: translate3d(0, 150px, 0);

      &.open {
        pointer-events: auto;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      & .linkWrapper {
        padding: 15px 0 0;

        & .link {
          font-size: 13px;
          font-weight: bold;
          position: relative;
          letter-spacing: 1px;
          text-transform: uppercase;
          width: max-content;

          & svg {
            width: 30px;
            height: 30px;
            position: relative;
            margin-right: 5px;
            padding: 7px;
            fill: ${ThemeGet('colors.white')};
          }
        }

        & .link:hover,
        & .link:focus {
          color: ${ThemeGet('colors.white')};
        }

        & .linkComponent {
          display: block;
          color: ${ThemeGet('colors.white')};
          position: relative;
          display: flex;
          align-items: center;
        }

        & .linkComponent:hover:before {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }

      @media (max-width: 640px) {
        gap: 0;
        padding: 0;
        &:first-child {
          margin-top: 40px;
        }
      }
    }
  }

  & .stackWrapper .socialMediaContainer {
    width: max-content;

    & .linkWrapper {
      width: max-content;
    }
  }

  & .stackContainer {
    position: absolute;
    top: 0;
    left: 10%;
    width: 30%;
    padding: 40px;
    @media (max-width: 1466px) {
      width: 50%;
      top: 0;
    }
    @media (max-width: 879px) {
      width: 70%;
    }
    @media (max-width: 640px) {
      width: 100%;
      left: 0;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0 40px;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: baseline;
      top: -20px;
    }
  }

  & .socialMediaWrapper {
    width: 20%;
    margin-top: 40px;

    & .socialMediaContainer {
      display: flex;
      gap: 20px;

      & svg {
        width: unset !important;
        height: unset  !important;
        padding: 5px !important;
        fill: ${ThemeGet('colors.white')};
      }
    }
  }

  .stackWrapper
  .linkWrapper {
    padding: 10px 0;
    width: 100px;
  }

  .stackWrapper .stackComponents {
    z-index: 100;
    pointer-events: none;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    -webkit-perspective-origin: 50% -50%;
    perspective-origin: 50% -50%;

    & canvas {
      transition: filter 0.45s;
    }
  }

  .stackWrapper .component {
    position: relative;
    z-index: 5;
    width: 100%;
    overflow: auto;
    pointer-events: auto;
    background-color: ${ThemeGet('colors.black')};
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .stackWrapper .stackComponents.open .component {
    cursor: pointer;
    height: 100vh;
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: transform 0.45s, opacity 0.45s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);

    & canvas {
      transition: filter 0.45s;
      filter: opacity(1);

      &:first-child {
        filter: opacity(0);
      }
    }
  }

  .stackWrapper .component.inactive {
    position: absolute;
    z-index: 0;
    top: 0;
    opacity: 0;
  }

  .humBurgerActionButton {
    position: absolute;
    z-index: 1000;
    top: 50px;
    right: 15%;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    border: none;
    z-index: 0;
    outline: none;
    background: transparent;
  }

  .humBurgerActionButton::before,
  .humBurgerActionButton::after,
  .humBurgerActionButton span {
    background: ${ThemeGet('colors.white')};
  }

  .humBurgerActionButton::before,
  .humBurgerActionButton::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 120%;
    height: 2px;
    pointer-events: none;
    transition: transform 0.25s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-color: ${ThemeGet('colors.white')};
  }

  .humBurgerActionButton span {
    position: absolute;
    left: 25%;
    overflow: hidden;
    width: 70%;
    height: 2px;
    text-indent: 200%;
    transition: opacity 0.25s;
    background-color: ${ThemeGet('colors.primary')};
  }

  .humBurgerActionButton::before {
    -webkit-transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);
    transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);
  }

  .humBurgerActionButton::after {
    -webkit-transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
    transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
  }

  .humBurgerActionButton.open span {
    opacity: 0;
  }

  .humBurgerActionButton.open::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
  }

  .humBurgerActionButton.open::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
  }

  @media screen and (max-width: 60em) {
    .linkWrapper {
      width: 50%;
      min-height: 20px;
    }

    .linkComponent {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 40em) {
    .stackWrapper .stackNavigationContainer {
      display: block;
      padding: 10px 20px 0 20px;
      text-align: left;
    }

    .stackWrapper .linkWrapper {
      width: 100%;
      padding: 4px 0;
    }

    .humBurgerActionButton {
      top: 45px;
      right: 30px;
      left: auto;
    }
  }

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  @keyframes cubeWidth {
    0% {
      width: 0;
      opacity: 1;
    }
    100% {
      width: 100vw;
      opacity: 1;
    }
  }

  @keyframes cubeHeight {
    0% {
      height: 0;
      opacity: 1;
    }
    100% {
      height: 100vw;
      opacity: 1;
    }
  }
  code {
    transform: translateZ(0);
    min-width: 100%;
    float: left;
    & > span {
      display: block;
    }
  }
  & .popup-content {
    margin: auto;
    background: ${ThemeGet('colors.black')};
    height: 100vh;
    width: 100vw;
    padding: 50px 20px;
    overflow-y: scroll !important;
    -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

`


/*
 * EXPORTS
 */
export default Index
