/*
 * IMPORTS
 */
import Theme from './theme'


/*
 * IMPORTS
 */
export { Theme }
