
import Merge from 'lodash.merge' // NPM: lodash merge library.
import Get from 'lodash.get' // NPM: lodash get library.


/*
 * PACKAGES
 */
import Theme from '~packages/common/theme'


/*
 * GLOBALS
 */
const _theme = 'dark'


/*
 * OBJECTS
 */
const _GetTheme = __mode => Merge({}, Theme, { 'colors': Get(Theme.modes, __mode, Theme) })
const _ThemeReducer = (__prevState = { ..._GetTheme(_theme), 'mode': _theme }, __action) => {
  /*
   * Switch case for handling actions on
   * theme store.
   */
  switch (__action.type) {
  case 'THEME_UPDATE':
    // Return combined state.
    return {
      ..._GetTheme(__action?.Theme?.mode),
      'mode': __action?.Theme?.mode
    }
  default:
    // Return old state.
    return __prevState
  }
}


/*
 * EXPORTS
 */
export default _ThemeReducer
