/*
 * EXPORTS
 */
export default {
  'transparent': 'transparent',
  'success': '#4caf50',
  'danger': '#ff005c',
  'blue': '#464488',
  'lightBlue': '#00acee',
  'darkBlue': '#464488',
  'purple': '#9D82FD',
  'yellow': '#FFEA00',
  'primary': '#2948ff',
  'secondary': '#42D7F8',
  'ternary': '#4282fe',
  'pureBlackShadow': 'rgba(0, 0, 0, 0.06)',
  'pureBlack': '#000',
  'pureSemiLightBlack': '#292929',
  'pureLightestBlack': '#323232',
  'pureLightBlack': '#202020',
  'pureDarkBlack': '#101010',
  'pureDarkestWhite': '#dbdbdb',
  'pureDarkerWhite': '#acacac',
  'pureDarkWhite': '#e6e6e6',
  'pureLightDarkWhite': '#f8f8f8',
  'pureWhite': '#ffffff'
}
